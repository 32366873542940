import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetUserQuery } from "../../../features/users/usersApiSlice";
import { useGetUserEntitlementsQuery } from "../../../features/entitlements/entitlementsApiSlice";
import {
  useUpdateServiceProviderEntitlementMutation,
  useUpdateServiceProviderEntitlementStatusMutation,
} from "../../../features/users/serviceProvidersApiSlice";
import { useGetSpDistributionPointsQuery } from "../../../features/distribution_points/distributionPointsApiSlice";
import {
  Box,
  Paper,
  Card,
  Typography,
  CardContent,
  Button,
  ButtonGroup,
  Popover,
  TextField,
  Alert,
  Tab,
  Breadcrumbs,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import StyledDataGrid from "../../global/StyledDataGrid";
import { LinkColor, tokens } from "../../../theme";
import ConfirmChangeStatus from "../shared/ConfirmChangeStatus";
import * as yup from "yup";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";
import { useGetCardsQuery } from "../../../features/cards/cardsApiSlice";

const ViewServiceProvider = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(
    (permission) => permission.name === "entitlement-add"
  );

  const { t } = useTranslation();
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [serviceProvider, setServiceProvider] = useState([]);
  const [entitlements, setEntitlements] = useState([]);
  const [distributionPoints, setDistributionPoints] = useState([]);
  const [action, setAction] = useState(null);

  const [openModalDialog, setOpenModalDialog] = useState(false);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const { data, isLoading } = useGetUserQuery(id);

  useEffect(() => {
    if (data?.data) setServiceProvider(data?.data);
  }, [data]);

  const {
    data: entitlementsData,
    isLoading: fetchingEntitlements,
    isSuccess: entitlementsSuccess,
    refetch: refetchEntitlements,
  } = useGetUserEntitlementsQuery(id, {
    page,
    pageSize,
  });

  useEffect(() => {
    if (entitlementsData?.entitlements) {
      const activeEntitlements = entitlementsData.entitlements.filter(
        (entitlement) => entitlement.status === "ACTIVE"
      );
      setEntitlements(activeEntitlements);
    }
  }, [entitlementsData, fetchingEntitlements, entitlementsSuccess]);

  const { data: dpData, isLoading: fetchingDps } =
    useGetSpDistributionPointsQuery(id, {
      page,
      pageSize,
    });

  useEffect(() => {
    if (dpData?.distribution_points)
      setDistributionPoints(dpData?.distribution_points);
  }, [dpData, fetchingEntitlements, entitlementsSuccess]);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModalDialog = () => {
    setOpenModalDialog(false);
  };

  const [updateEntitlement, { isLoading: isUpdateLoading }] =
    useUpdateServiceProviderEntitlementMutation();
  const [updateEntitlementStatus, { isLoading: isUpdateStatusLoading }] =
    useUpdateServiceProviderEntitlementStatusMutation();

  const [selectedEntitlement, setSelectedEntitlement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleClick = (event, entitlementString) => {
    const entitlement = JSON.parse(entitlementString);

    setSelectedEntitlement({
      id: entitlement.id,
      title: entitlement.title,
      price: entitlement.price ? entitlement.price : 0,
      subsidy: entitlement.subsidy_amount ? entitlement.subsidy_amount : 0,
      copay: entitlement.copay_amount ? entitlement.copay_amount : 0,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (values) => {
    const userId = serviceProvider?.id;
    const entitlementId =
      typeof selectedEntitlement === "string"
        ? JSON.parse(selectedEntitlement)?.id
        : selectedEntitlement?.id;

    // Ensure the values are correctly formatted (e.g., as numbers)
    const body = {
      price: Number(values.price),
      subsidy: Number(values.subsidy),
      copay: Number(values.copay),
    };

    try {
      const response = await updateEntitlement({
        user_id: userId,
        entitlement_id: entitlementId,
        ...body,
      }).unwrap();

      if (response.status === 200) {
        handleClose();
        Toast(
          t("service_providers.edit_entitlements.update_success"),
          "success"
        );
        refetchEntitlements();
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const handleModalOpen = (entitlement, action) => {
    setSelectedEntitlement(entitlement);
    setAction(action);
    setOpenModalDialog(true);
  };

  const handleStatusChange = async (entitlement, action) => {
    const userId = serviceProvider?.id;
    const entitlementId =
      typeof selectedEntitlement === "string"
        ? JSON.parse(selectedEntitlement)?.id
        : selectedEntitlement?.id;

    const actionStatusMap = {
      ACTIVATE: "ACTIVE",
      DEACTIVATE: "INACTIVE",
      ARCHIVE: "ARCHIVED",
    };

    const status = actionStatusMap[action] || "";

    // Ensure you have non-null values before proceeding
    if (!userId || !entitlementId) {
      Toast(t("service_providers.edit_entitlements.missing_request_fields"));
      return;
    }

    try {
      const response = await updateEntitlementStatus({
        entitlement_id: entitlementId,
        user_id: userId,
        new_status: status,
      }).unwrap();

      if (response.status === 200) {
        Toast(
          t("service_providers.edit_entitlements.update_success"),
          "success"
        );
        refetchEntitlements();
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const initialValues = {
    price: "",
    subsidy: "",
    copay: "",
  };

  const entitlementsSchema = yup.object().shape({
    price: yup.string().required(t("packages.form.helpertext.price")),
    subsidy: yup
      .string()
      .required(t("packages.form.helpertext.subsidy_amount")),
    copay: yup.string().required(t("packages.form.helpertext.copay_amount")),
  });

  const columns = [
    {
      field: "actions",
      headerName: t("global.table.actions"),
      cellClassName: (params) =>
        params.row.status === "INACTIVE" ? "archived-row" : "",
      renderCell: (params) => {
        const getEntitlement = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        const renderActionButton = (status, id) => {
          const commonProps = {
            color: "primary",
          };

          if (status === "INACTIVE") {
            return (
              <>
                <Button
                  color="inherit"
                  onClick={() => handleModalOpen(getEntitlement(), "ARCHIVE")}
                >
                  {t("global.table.archive_button")}
                  <ArchiveOutlinedIcon fontSize="small" />
                </Button>
                <Button
                  color="warning"
                  onClick={() => handleModalOpen(getEntitlement(), "ACTIVATE")}
                >
                  {t("global.table.activate_button")}
                  <CheckCircleOutlinedIcon fontSize="small" />
                </Button>
              </>
            );
          } else {
            return (
              <Button
                color="error"
                onClick={() => handleModalOpen(getEntitlement(), "DEACTIVATE")}
              >
                {t("global.table.delete_button")}
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </Button>
            );
          }
        };

        return (
          <ButtonGroup variant="outlined" size="small">
            {params.row.status !== "INACTIVE" && (
              <>
                <Button
                  color="secondary"
                  onClick={(e) => handleClick(e, getEntitlement())}
                >
                  {t("global.table.edit_button")}
                  <EditOutlinedIcon fontSize="small" />
                </Button>
                <Popover
                  id={popoverId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography
                    variant="h6"
                    align="left"
                    style={{ padding: "8px" }}
                  >
                    {t("service_providers.edit_entitlements.edit")}{" "}
                    <b>{selectedEntitlement?.title}</b>{" "}
                    {t("entitlements.form.label.entitlement")}{" "}
                    {t("service_providers.edit_entitlements.in")}{" "}
                    <b>{serviceProvider?.user_name}</b>{" "}
                    {t("service_providers.add_entitlements.service_provider")}
                  </Typography>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={selectedEntitlement}
                    validationSchema={entitlementsSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          fullWidth
                          variant="filled"
                          type="number"
                          label={t("entitlements.form.label.price")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.price}
                          name="price"
                          error={!!touched.price && !!errors.price}
                          helperText={touched.price && errors.price}
                        />
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label={t("packages.form.label.copay_amount")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.copay}
                          name="copay"
                          error={!!touched.copay && !!errors.copay}
                          helperText={touched.copay && errors.copay}
                        />
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label={t("packages.form.label.subsidy_amount")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.subsidy}
                          name="subsidy"
                          error={!!touched.subsidy && !!errors.subsidy}
                          helperText={touched.subsidy && errors.subsidy}
                        />
                        <Box
                          display="flex"
                          justifyContent="end"
                          gap="10px"
                          mt="20px"
                          mr="2px"
                          mb="10px"
                        >
                          <button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            className="btn btn-primary"
                            size="small"
                          >
                            {t("global.buttons.edit_entitlements_tosp")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            size="small"
                            onClick={handleClose}
                          >
                            {t("global.page.cancel_button")}
                          </button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Popover>
              </>
            )}
            {renderActionButton(params.row.status, params.row.id)}{" "}
            {/* Deactivate/Unarchive */}
          </ButtonGroup>
        );
      },
      sortable: false,
      width: 200, // Adjust as per your design requirements
    },
    { field: "title", headerName: t("global.form.label.name"), flex: 1 },
    { field: "unit", headerName: t("entitlements.form.label.unit"), flex: 1 },
    { field: "price", headerName: t("entitlements.form.label.price"), flex: 1 },
    {
      field: "copay_amount",
      headerName: t("packages.form.label.copay_amount"),
      flex: 1,
    },
    {
      field: "subsidy_amount",
      headerName: t("packages.form.label.subsidy_amount"),
      flex: 1,
    },

    {
      field: "status",
      headerName: t("entitlements.form.label.status"),
      flex: 1,
    },
  ];

  const dpColumns = [
    {
      field: "title",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.form.label.description"),
      flex: 1,
    },
    {
      field: "location",
      headerName: t("distribution_points.form.label.location"),
      flex: 1,
    },
  ];

  const content =
    isLoading || fetchingDps || fetchingEntitlements ? (
      <Spinner />
    ) : (
      <Box m="20px">
        <ToastContainer />
        <Header
          title={t("service_providers.view.title")}
          subtitle={t("service_providers.view.subtitle")}
        />

        <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
          <Link style={LinkColor()} color="inherit" to="/">
            <Typography variant="body2">{t("sidebar.Home")}</Typography>
          </Link>
          <Link style={LinkColor()} color="inherit" to="/service-providers">
            <Typography variant="body2">
              {t("sidebar.Service Providers")}
            </Typography>
          </Link>
          <Typography color="text.primary" variant="body2">
            {t("service_providers.view.view")}
          </Typography>
        </Breadcrumbs>

        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          <Paper variant="outlined">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {serviceProvider?.user_name}
                </Typography>
                <hr />
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("users.form.label.first_name")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {serviceProvider.first_name}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("users.form.label.last_name")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {serviceProvider.last_name}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("users.form.label.email")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {serviceProvider.email}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("users.form.label.id_number")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {serviceProvider.index_number}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("users.form.label.user_name")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {serviceProvider.user_name}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.entitlements")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {entitlements?.length}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("sidebar.Distribution Points")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {distributionPoints?.length}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Box>

        {/* Entitlements In the service provider */}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab label={t("sidebar.Entitlements")} value="1" />
                <Tab label={t("sidebar.Distribution Points")} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box className="data_grid_box_container_within_tabs">
                {canAdd && (
                  <Box
                    mb="10px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Link
                      to={`/service-providers/${serviceProvider.id}/add/entitlements`}
                      underline="none"
                    >
                      <button className="btn btn-primary">
                        {t("global.buttons.add_entitlements")}
                      </button>
                    </Link>
                  </Box>
                )}
                {entitlements?.length < 1 ? (
                  <Alert severity="info">
                    {t("service_providers.index.no_entitlements_data")}
                  </Alert>
                ) : (
                  <StyledDataGrid
                    rows={entitlements}
                    columns={columns}
                    rowsPerPageOptions={default_rows_per_page_options}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value="2">
              {/* Distributions In the service provider */}
              <Box className="data_grid_box_container_within_tabs">
                <Box
                  mb="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                ></Box>
                {distributionPoints?.length < 1 ? (
                  <Alert severity="info">
                    {t("service_providers.index.no_distribution_point_data")}
                  </Alert>
                ) : (
                  <StyledDataGrid
                    rows={distributionPoints}
                    columns={dpColumns}
                    rowsPerPageOptions={default_rows_per_page_options}
                  />
                )}
              </Box>
            </TabPanel>
            <Box>
              <ConfirmChangeStatus
                open={openModalDialog}
                onClose={handleCloseModalDialog}
                child={selectedEntitlement}
                parent={{
                  title: `${serviceProvider?.first_name} ${serviceProvider?.last_name}`,
                }}
                action={action}
                handleChangeStatus={handleStatusChange}
                childLabel={t("entitlements.form.label.entitlement")}
                parentLabel={t(
                  "service_providers.add_entitlements.service_provider"
                )}
              />
            </Box>
          </TabContext>
        </Box>
      </Box>
    );

  return content;
};

export default ViewServiceProvider;